<template>
  <div class="wechat_warn_container tac card flc flex_direction">
    <p class="mb20">----------没有搜索到？试试人工查找吧---------</p>
    <h4 class="title mb10 bold fs14 ">*专属客服帮您查找*</h4>
    <img class="wechat_code_img" src="https://img.infox-med.com/webImg/public/wechatCode.png"
         alt="">
    <p class="code_warn">扫码添加客服微信</p>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.wechat_warn_container {
  height: 320px;
  padding-top: 20px;
  .wechat_code_img {
    height: 150px;
  }
  .code_warn {
    padding-left: 4px;
    margin-top: 10px;
    letter-spacing: 8px;
  }
}
</style>
