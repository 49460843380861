<template>
  <!--  -->
  <MyDialog :isShow="isExportDialog" width="900px" :title="`批量导出文献格式(${selectDocIdList.length}篇)`"
            :hedeDialog="hedeExportDialog" zIndex="2000">
    <div class="export_content" v-loading="loading">
      <div class="top_btn_box display_flex alignItems_center flex_spaceBetween">
        <div class="view_type">
          <span class="options_btn mr10" @click="changeViewType(1)"
                :class="{'activate_view':viewType===1 }">按文献</span>
          <span class="options_btn" @click="changeViewType(2)"
                :class="{'activate_view':viewType===2 }">按格式</span>
        </div>
        <div class="tab_box">
          <span class="key">选择格式:</span>
          <span class="tab_item no_select" :class="{'is_select_tab': selectTabs.includes(item.key)}"
                v-for="item in formatList" :key="item.key"
                @click="tabChange(item)">{{item.label}}</span>
        </div>
        <div class="export_vtn_box">
          <span class="mr10 options_btn" @click="onEmptySelect">清空选中文献</span>
          <span class="options_btn" @click="exportClick">导出选中格式</span>
        </div>
      </div>
      <div class="format_content">
        <template v-if="viewType === 1">
          <div class="format_item" v-for="(item) in docFormatList" :key="item.key">
            <div class="boc_title_box display_flex">
              <div class="boc_title fs14">{{item.title}}</div>
              <i @click="onDeleteItem(item)"
                 class="delete_icon el-icon-circle-close flex_shrink0 hand"></i>
            </div>
            <div v-for="key in selectTabs" :key="key" class="format_box">
              <template v-if="item[key]">
                <span class="format_name">
                  {{formatMap[key]}}
                </span>
                <div class="format_txt row_3">
                  {{item[key]}}
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="viewType === 2">
          <div v-for="key in  selectTabs" :key="key" class="format_item format_type_item">
            <p class="format_type">{{formatMap[key]}}</p>
            <div class="format_text" v-for="item in formatInfo[key]" :key="item.content">
              {{item.content}}
            </div>
          </div>
        </template>
      </div>

    </div>
  </MyDialog>
</template>
<script>
import MyDialog from '@/components/myDialog/index.vue'
import { confirm, ExportExcel } from '@/utils/index.js'
import { getFormatList } from '@/api/article.js'
export default {
  components: {
    MyDialog
  },
  props: {
    isExportDialog: {
      type: Boolean,
      default: false
    },
    selectDocIdList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      viewType: 1,
      formatList: [
        {
          key: 'citeAma',
          label: 'AMA'
        },
        {
          key: 'citeApa',
          label: 'APA'
        },
        {
          key: 'citeMla',
          label: 'MLA'
        },
        {
          key: 'citeNlm',
          label: 'NLM'
        }
      ],
      formatMap: {
        citeAma: 'AMA',
        citeApa: 'APA',
        citeMla: 'MLA',
        citeNlm: 'NLM'
      },
      selectTabs: ['citeAma'], // 选中的tab
      docFormatList: [],
      formatInfo: {}
    }
  },
  methods: {
    changeViewType (type) {
      if (this.viewType === type) return
      this.viewType = type
    },
    // 删除某一项
    onDeleteItem (row) {
      const i = this.docFormatList.findIndex(item => row.documentId === item.documentId)
      if (i !== -1) this.docFormatList.splice(i, 1)
      this.$emit('deleteItem', Number(row.documentId))
    },
    // 获取引用格式列表
    async getFormatList () {
      try {
        this.loading = true
        const list = this.selectDocIdList.map(item => item.toString())
        const res = await getFormatList(list)
        this.docFormatList = res.data
        console.log(this.docFormatList)
        this.setFormatInfo()
      } catch (error) {
        this.$myMessage.warning('获取失败请重试')
        this.hedeExportDialog()
      } finally {
        this.loading = false
      }
    },
    // 获取引用格式列表
    openDialog () {
      this.getFormatList()
    },
    // 清空选中
    onEmptySelect () {
      confirm({
        warning: `确认清空选择的${this.selectDocIdList.length}条文献`,
        success: () => {
          this.$emit('emptySelectAll')
          this.hedeExportDialog()
        }
      })
    },
    exportClick () {
      this.viewType === 2 ? this.setFormatItemExport() : this.setDocItemExport()
    },
    setFormatItemExport () {
      const tableHeadMap = [
        { title: '序号', dataIndex: 'index' },
        { title: '格式', dataIndex: 'format' },
        { title: '内容', dataIndex: 'content' },
        { title: '标题', dataIndex: 'title' }
      ]
      const setColumnStyle = ExportExcel.setColumnStyle({
        2: { width: 80 },
        3: { width: 60 },
        default: { width: 10 }
      })
      const data = this.selectTabs.reduce((res, key, i) => {
        this.formatInfo[key].forEach(item => {
          res.push({
            index: i + 1,
            title: item.title,
            content: item.content,
            format: this.formatMap[key]
          })
        })
        return res
      }, [])
      const config = {
        tableHeadMap,
        setColumnStyle,
        data
      }
      this.onExportExcel(config)
    },
    setDocItemExport () {
      const tableHeadMap = [
        { title: '序号', dataIndex: 'index' },
        { title: '标题', dataIndex: 'title' },
        { title: '格式', dataIndex: 'format' },
        { title: '内容', dataIndex: 'content' }
      ]
      const setColumnStyle = ExportExcel.setColumnStyle({
        1: { width: 60 },
        3: { width: 80 },
        default: { width: 10 }
      })
      const data = this.docFormatList.reduce((res, item, i) => {
        this.selectTabs.forEach(key => {
          res.push({
            title: item.title,
            index: i + 1,
            format: this.formatMap[key],
            content: item[key]
          })
        })
        return res
      }, [])
      const config = {
        tableHeadMap,
        setColumnStyle,
        data
      }
      this.onExportExcel(config)
    },
    // 导出
    onExportExcel (config) {
      // 表头配置
      const excelHead = [
        {
          title: 'Info X Med',
          children: config.tableHeadMap
        }
      ]
      // 设置列样式 (列的宽和列的字体样式)

      const border = {
        top: { style: 'thin', color: { argb: 'ffd4d4d4' } },
        left: { style: 'thin', color: { argb: 'ffd4d4d4' } },
        bottom: { style: 'thin', color: { argb: 'ffd4d4d4' } },
        right: { style: 'thin', color: { argb: 'ffd4d4d4' } }
      }
      // 设置单元格样式 (字体 对齐)
      const setCellStyle = ({ columnIndex, type, rowIndex }) => {
        if (type === 'main' && [2, 3].includes(columnIndex)) {
          const alignment = { vertical: this.viewType === 1 ? (columnIndex === 3 ? 'top' : 'middle') : 'top', horizontal: this.viewType === 1 ? (columnIndex === 3 ? 'left' : 'center') : 'left', wrapText: true }

          if (rowIndex % 2 === 0) {
            return {
              style: {
                alignment,
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'fff9f9f9' }
                },
                border
              }
            }
          } else {
            return {
              style: {
                alignment
              }
            }
          }
        }
        if (type === 'main' && columnIndex === 1) {
          return {
            style: {
              alignment: { vertical: 'middle', horizontal: this.viewType === 1 ? 'left' : 'center', wrapText: true }
            }
          }
        }
        if (type === 'header') {
          return {
            style: {
              font: {
                bold: true,
                color: rowIndex === 0 ? { argb: 'ff4b639f' } : { argb: 'ff000000' }
              },
              alignment: { vertical: 'middle', horizontal: 'center' },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'fff4f5fa' }
              },
              border
            }
          }
        }
      }
      // 设置单元格合并
      const spanMethod = ({ columnIndex }) => {
        if (columnIndex === 0 || columnIndex === 1) {
          return {
            rowspan: this.selectTabs.length,
            colspan: 1
          }
        }
      }

      const exportExcelConfig = {
        column: excelHead,
        data: config.data,
        sheetName: 'Info X Med',
        setRowStyle: ExportExcel.setRowStyle(30, 80), // 设置行样式(行的高)
        setColumnStyle: config.setColumnStyle,
        setCellStyle,
        spanMethod
      }
      const formatLabels = this.selectTabs.reduce((arr, item) => {
        arr.push(this.formatMap[item])
        return arr
      }, [])
      const fileName = 'infoxmed-' + formatLabels.join('-')
      ExportExcel({
        exportExcelConfig,
        fileName,
        successMsg: '已下载至本地, 请查看',
        successCallback: () => {
          this.$emit('exportExcelComplete')
          this.hedeExportDialog()
        }
      })
    },
    // tab栏切换
    tabChange (item) {
      const i = this.selectTabs.indexOf(item.key)
      if (i !== -1) {
        if (this.selectTabs.length === 1) return
        this.$delete(this.selectTabs, i)
      } else {
        this.selectTabs.push(item.key)
      }
    },
    hedeExportDialog () {
      this.selectTabs = this.$options.data().selectTabs
      this.docFormatList = []
      this.viewType = 1
      this.$emit('update:isExportDialog', false)
    },
    setFormatInfo () {
      const keys = Object.keys(this.formatMap)
      this.formatInfo = keys.reduce((res, key) => {
        const info = this.docFormatList.map(item => ({
          content: item[key],
          title: item.title
        }))
        res[key] = info
        return res
      }, {})
      console.log(this.formatInfo)
    }
  }

}
</script>
<style lang="less" scoped>
.user_info_edire {
  /deep/ .my_dialog_box {
    top: 70px;
    .my_dialog_top_box {
      font-size: 16px;
    }
  }
}
.export_content {
  // min-height: 500px;
  max-height: calc(100vh - 150px);
  padding: 0 20px;
}
.top_btn_box {
  width: 100%;
  height: 40px;
  color: #4b639f;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  .options_btn {
    cursor: pointer;
    padding: 4px 10px 3px;
    border-radius: 4px;
    &:hover {
      background-color: #4b639f;
      color: #fff;
    }
  }
  .activate_view {
    background-color: #4b639f;
    color: #fff;
  }
  .key {
    cursor: default;
    color: #999;
    margin-right: 10px;
  }
  .tab_box {
    .tab_item {
      margin-right: 10px;
      padding: 4px 10px;
      border-radius: 2px;
    }
    .is_select_tab {
      background-color: #4b639f;
      color: #fff;
    }
  }
}
.format_content {
  width: 100%;
  max-height: calc(100vh - 245px);
  overflow-y: auto;
  padding-bottom: 15px;
  .format_item {
    margin-bottom: 15px;
    background-color: #f5f7fb;
    // color: #f0f0f0;
    border-radius: 2px;
    padding: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .boc_title_box {
      width: 100%;
      .boc_title {
        width: 98%;
        color: #4b639f;
        word-break: break-all;
        margin-bottom: 10px;
      }
      .delete_icon {
        margin-left: 5px;
        font-size: 18px;
        color: #666;
        &:hover {
          color: #4b639f;
        }
      }
    }

    .format_box {
      color: #666;
      line-height: 1.5em;
      word-break: break-all;
      .format_name {
        padding: 1px 2px;
        background-color: #4b639f;
        color: #fff;
      }
    }
  }
}

.format_type_item {
  .format_type {
    font-weight: 600;
    color: #4b639f;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .format_text {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
